module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hoolaloom","defaultLang":"en-ca","langs":["en-ca","fr-ca"],"previews":false,"pages":[{"type":"Item","match":"/:lang?/pieces/:uid","path":"/pieces","component":"/opt/build/repo/src/templates/item.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hoolaloom","short_name":"Hoolaloom","description":"Artisanal Weaving","start_url":"/","lang":"en-CA","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","icon":"src/images/favicon.png","localize":[{"start_url":"/fr-ca/","lang":"fr-CA","name":"Hoolaloom","short_name":"Hoolaloom","description":"Tissage artisanal"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d0f8cba7c6d182fda7350355851d22e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
